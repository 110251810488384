import React, {useState} from "react";
import ReactSlider from "react-slider";

interface AudioProps {
    audioInfo: {
        [key: string]: any;
        chords: string[];
    } | null;
}

function Audio({audioInfo}: AudioProps) {
    if (audioInfo !== null)
        return (
            <div>
                <audio id="the-sound" key={audioInfo.src} preload="auto" loop>
                    <source src={audioInfo.src} type={audioInfo.type}/>
                    Your browser does not support the audio tag.
                </audio>
            </div>
        );
}

function partitionArray(array: any[], partitionSize: number) {
    const result = [];
    for (let i = 0; i < array.length; i += partitionSize) {
        result.push(array.slice(i, i + partitionSize));
    }
    return result;
}

function processChords(chords: string[]){
    type AccType = {
        result: string[];
        prevChord: string;
    }

    return chords.reduce((acc: AccType, chord) => {
        if (chord === acc.prevChord) {
            acc.result.push('/');
        } else {
            acc.result.push(chord);
        }
        acc.prevChord = chord;
        return acc;
    }, { result: [], prevChord: "" }).result;
}

function Chords({audioInfo}: AudioProps) {
    if (audioInfo !== null) {
        const chords = processChords(audioInfo.chords);
        const chordsPerLine = 16;
        const partitionedChords = partitionArray(chords, chordsPerLine);

        return (
            <table className="chords">
                <tbody>
                {partitionedChords.map((chordsBatch: string[], index) => {
                    return (
                        <React.Fragment key={index}>
                            <tr>
                                {chordsBatch.map((chord: string) => <td> {chord} </td>)}
                            </tr>
                        </React.Fragment>
                    );
                })}
                </tbody>
            </table>
        );
    }

    return null;
}

function SoundInfo({audioInfo}: AudioProps) {
    let hidden_keys = new Set(["src", "type", "chords"]);
    return (
        <table className="sound-info">
            <tbody>
            {Object.keys(audioInfo!)
                .filter(k => !hidden_keys.has(k))
                .map(k => (
                    <tr key={k}>
                        <td>{k}</td>
                        <td>{k.indexOf("-\u003e") >= 0 ? Math.round(parseFloat(audioInfo![k]) * 1000) + " ms" : audioInfo![k]}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

function Category(props: {
    selectedCategory: string,
    category: string,
    setSelectedCategory: (value: (((prevState: string) => string) | string)) => void
}) {
    let imgClassNames = "category-image"
    if (props.selectedCategory !== props.category)
        imgClassNames += " greyed-out"
    return (
        <div className="category">
            <img onClick={() => props.setSelectedCategory(props.category)} alt={props.category}
                 className={imgClassNames} src={"images/" + props.category + ".jpg"}/>
            <div onClick={() => props.setSelectedCategory(props.category)}
                 className="category-text">{props.category}</div>
        </div>
    )
}

// fakeSong was added for development, so we see the layout immediately, without having to generate something first
const fakeSong = {
    src: "/sounds/f174cbb78c774304a715b6876d49ea41.mp3",
    type: "audio/mpeg",
    title: "Demo Song With A Rather Longish Title",
    key: "Cm",
    composer: "Wolfgang Goethe",
    chords: [ "Cm", "Cm", "Cm", "Cm", "Gm", "Gm", "Gm", "Gm" ],
    bars: "10",
    "-\u003emid": 0.01337299999431707,
    "mid-\u003ewav": 0.6768940000038128,
    "wav-\u003emp3": 0.24899499998718966
};

function App() {
    const [generatedSound, setGeneratedSound] = useState(null);
    const [generating, setGenerating] = useState(false);

    const [playing, setPlaying] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("Jazz");
    const [bpmValue, setBpmValue] = useState(120);

    function togglePlay() {
        const theSound = document.getElementById('the-sound') as HTMLAudioElement;
        if (theSound == null)
            return;
        if (playing) {
            theSound.pause();
            setPlaying(!playing)
        } else {
            theSound.play()
                .then(() => setPlaying(!playing))
                .catch((error) => {
                    console.error('Audio playback error:', error);
                });
        }
    }

    function stop() {
        const theSound = document.getElementById('the-sound') as HTMLAudioElement;
        if (theSound == null)
            return;
        if (playing) {
            theSound.pause();
            theSound.currentTime = 0;
            setPlaying(!playing)
        }
    }

    function generate(selectedCategory: string, bpmValue: number) {
        const encodedCategory = encodeURIComponent(selectedCategory);
        const randomSeed = Math.floor(Math.random() * 12345678);
        setGenerating(true);
        fetch(`/api/generate?c=${encodedCategory}&r=${randomSeed}&bpm=${bpmValue}`)
            .then((res) => res.json())
            .then((res) => setGeneratedSound(res))
            .catch((error) => {
                console.error("Error:", error);
            })
            .finally(() => {
                setPlaying(false);
                setGenerating(false);
            });
    }

    let song = generatedSound || fakeSong;

    return (
        <div>
            <div id="sound-info-panel">
                <SoundInfo audioInfo={song}/>
            </div>
            <section id="main">
                <section id="categories">
                    <Category category="Jazz" selectedCategory={selectedCategory}
                              setSelectedCategory={setSelectedCategory}/>
                    <Category category="Brazilian" selectedCategory={selectedCategory}
                              setSelectedCategory={setSelectedCategory}/>
                    <Category category="Latin" selectedCategory={selectedCategory}
                              setSelectedCategory={setSelectedCategory}/>
                    <Category category="Blues" selectedCategory={selectedCategory}
                              setSelectedCategory={setSelectedCategory}/>
                    <Category category="Pop" selectedCategory={selectedCategory}
                              setSelectedCategory={setSelectedCategory}/>
                    <Category category="Country" selectedCategory={selectedCategory}
                              setSelectedCategory={setSelectedCategory}/>
                </section>
                <section>
                    Tempo:
                    <ReactSlider
                        step={10}
                        min={60}
                        max={260}
                        marks={true}
                        className="horizontal-slider"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                        value={bpmValue}
                        onChange={value => setBpmValue(value)}
                    />
                </section>
                <section>
                    <Audio audioInfo={song}/>
                    <button onClick={() => generate(selectedCategory, bpmValue)} className={generating ? "button-blinking" : "button"} role="button">
                        Generate
                    </button>
                    <button onClick={togglePlay} className={`button ${song === fakeSong ? 'greyed-out' : ''}`}
                            role="button">{playing ? "Pause" : "Play"}
                    </button>
                    <button onClick={stop} className={`button ${playing ? '' : 'greyed-out'}`}
                            role="button">Stop
                    </button>
                    <Chords audioInfo={song}/>
                </section>
            </section>
        </div>
    );
}

export default App;
